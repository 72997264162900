// .login-footer {
// 	display: flex;
// 	justify-content: space-around;
// 	// position: absolute;
// 	min-width: 328px;
// 	max-width: 560px;
// 	width: 100%;
// 	height: 20px;
// 	margin: 0 auto;
// 	margin-top: 10%;
// 	margin-bottom: 30px;
// 	// left: calc(50% - 560px / 2);
// 	// bottom: 0;
// }
.login-footer {
  display: flex;
  justify-content: space-around;
  position: absolute;
  max-width: 560px;
  width: 100%;
  height: 20px;
  bottom: 0;
  // top:80%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
// .field-footer{
// 	display: flex;
// 	margin: 0 auto;
// 	justify-content: space-around;
// 	// max-width: 560px;
// 	// margin-top: 0.5%;
// 	// width: 100%;
// 	// height: 50px;
// }
.reset-link {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 50%;
  color: #7b2cbf !important;
}
