.event-avatar,
.event-avatar-number,
.event-avatar-image {
  // margin-right: 18px;
  min-width: 38px;
  max-width: 38px;
  min-height: 38px;
  max-height: 38px;
  background: rgb(73, 80, 87);
  color: rgb(248, 249, 250);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &-number {
    position: absolute;
    left: 40px;
    top: -22px;
  }

  &-image {
    background: none;
  }
}

.events-menu-container {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background: #ffffff;
  // border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}
