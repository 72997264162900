// @import url("https://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:300italic,700italic,400,300&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: poppins;
  src: url(../font/Poppins-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url(../font/Roboto-Regular.ttf);
  font-display: swap;
}

//********************Assets
@import "./style-assets/colors";
@import "./style-assets/typography";
@import "./style-assets/page";
@import "./style-assets/misc";

//*********************Mixins
@import "./mixins/typegraphyMixin";

//*********************Typography
@import "./style-assets/typography";

//*********************Color
@import "./style-assets/colors";

//******************Components

@import "./components/sideMenus";
@import "./components/cards";
@import "./components/settings";
@import "./components/tables";
@import "./components/alerts";
@import "./components/popup";
@import "./components/menu";
@import "./components/teams";
@import "./components/events";
@import "./components/circularLoader";
@import "./components/muiTable";
@import "./components/sidebar";
@import "./components/datepicker";

//*****************Layouts

@import "./layouts/signupFlow";
@import "./layouts/companySetup";
@import "./layouts/login";
@import "./layouts/billings";
@import "./layouts/dashboard";
@import "./layouts/objectives";
@import "./layouts/flashIconMenu";

//*****************Sub-Components
@import "./sub-components/button";
@import "./sub-components/tabs";
@import "./sub-components/field";
@import "./sub-components/header";
@import "./sub-components/input";
@import "./sub-components/switch";
@import "./sub-components/radio";
@import "./sub-components/loaders";
@import "./sub-components/skeleton.scss";
