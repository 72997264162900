.added-banner,
.added-banner-mobile {
  background: rgba(123, 44, 191, 0.2);
  color: rgb(123, 44, 191);
  padding: 8px;
  border: 1px solid rgb(233, 236, 239);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.added-banner {
  margin: 0 16px;
  // min-width:280px;
}
.added-banner-mobile {
  // position: relative;
  display: none;
  margin-top: 8px;
  min-width: 240px;
  // max-width:260px;
}
@media (max-width: 425px) {
  .added-banner {
    background: rgba(123, 44, 191, 0.2);
    margin-left: 0px;
    color: rgb(123, 44, 191);
    padding: 3px;
    border: 1px solid rgb(233, 236, 239);
    border-radius: 4px;
    display: block;
    font-size: 15px;
  }
}
.MuiTablePagination-selectLabel {
  position: absolute;
  top: 28px !important;
  right: 89px !important;
  margin-bottom: 0;
}

.css-pdct74-MuiTablePagination-selectLabel {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  letter-spacing: 0.25px !important;
  color: #343a40 !important;
}

.MuiTablePagination-select {
  position: absolute;
  // top: -2px !important;
  border: 1.5px solid #e4e4e4 !important;
  width: 18px !important;
  border-radius: 5px !important;
  right: 0;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  letter-spacing: 0.25px !important;
  color: #343a40 !important;
}

.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  letter-spacing: 0.25px !important;
  color: #343a40 !important;
}

.MuiTablePagination-displayedRows {
  //   position: absolute;
  //   right: 0;
  margin-bottom: 0;
  display: none;
}
.MuiTablePagination-actions {
  position: absolute;
  // left: -13px;
  margin-left: -23px !important;
  //   display: none;
}
.add-team .MuiTablePagination-actions button {
  margin-right: 52px !important;
  margin-top: -3px;
}
.MuiTablePagination-actions button {
  // for local
  // margin-right: 30px !important;
  // for pr
  margin-right: 42px !important;
}

.Teams .MuiTablePagination-actions button {
  // for local
  // margin-right: 30px !important;
  // for pr
  margin-right: 57px !important;
  margin-left: 18px !important;
  // margin-top: 25px;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin-top: 23px;
}

// THIS CLASS ADDS IN PR
.css-8nphli .MuiTablePagination-actions {
  margin-top: 26px;
}
// THIS CLASS ADDS IN PR
.css-rmmij8 {
  margin-top: 25px;
}

.css-1wif0xq .MuiTablePagination-actions {
  margin-left: -23px !important;
  padding-left: 0px !important;
}
.css-1wif0xq {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar
  .MuiTablePagination-actions {
  margin-left: 0px !important;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
  padding: 0 !important;
  padding-right: 0 !important;
  // margin-left: 0px !important;
  flex: 1 !important;
  min-height: 78px !important;
}
.table-page-info,
.checkbox-table-page-info,
.teams-table-page-info {
  position: absolute;
  margin-top: 24px;
  //   bottom: 0;
}
.table-page-info {
  left: 110px;
}

.checkbox-table-page-info {
  left: 110px;
}

.teams-table-page-info {
  left: 40px;
}

.table-page-info span,
.checkbox-table-page-info span,
.teams-table-page-info span {
  margin: 0 3px;
}

.css-1q1u3t4-MuiTableRow-root.sideMenu {
  background: #f8f9fa !important;
  //   text-align: left !important;
}

.css-1gplja-MuiTable-root {
  min-width: 100% !important;
}

.css-1xeot07-MuiPaper-root {
  background-color: #f8f9fa !important;
}

.tags-banner {
  background-color: black !important;
}
// TABLET
@media only screen and (max-width: 768px) {
  .table-page-info {
    left: 80px;
  }
  .checkbox-table-page-info {
    left: 73px;
    margin-top: 13px;
  }
}

@media only screen and (max-width: 576px) {
  .added-banner {
    display: none;
  }
  .added-banner-mobile {
    display: flex;
  }
}

@media only screen and (max-width: 425px) {
  .checkbox-table-page-info {
    left: 57px;
  }
  .teams-table-page-info {
    left: 33px;
  }
}
// MOBILE
@media only screen and (max-width: 375px) {
  .table-page-info {
    left: 80px;
  }
  .MuiInputBase-root .MuiInputBase-colorPrimary .css-rmmij8 {
    margin-right: 11px !important;
  }
  .css-rmmij8 {
    margin-right: 11px !important;
  }

  .table-page-info span {
    margin: 0 0px;
  }

  // .MuiTablePagination-actions {
  //   margin-left: 23px !important;
  // }
  .MuiTablePagination-selectLabel {
    right: 50px !important;
  }

  .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
    min-width: 8px !important;
  }
}
