::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #f1f1f1;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ced4da;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-box,
.modal-box-expanded {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  background-color: white;
  box-shadow: 24;
  padding: 24px;
  max-width: 818px !important;
  min-width: 290px !important;
}

.modal-box,
.remove-teams-modal {
  display: flex;
  // justify-content: center;
  flex-direction: column;

  border: none !important;
  // margin: 0 240px !important;
  // width: 90% !important;
  border-radius: 10px !important;
  ::-webkit-scrollbar {
    width: 0px;
  }
}

.modal-box {
  max-width: 818px !important;
  height: 482px;
  padding: 50px 80px !important;
}

.remove-teams-modal {
  width: 55vw !important;
  align-items: center;
  max-width: 611px;
  min-width: 311px;
  height: 276px;
}

.modal-box-expanded {
  height: 90vh;
}

.popup-content-container {
  padding: 50px 32px 0 32px;
  background: white;
  width: 818px;
  min-width: 328px;
  // min-height:482px;
  height: 85vh;
  max-height: 819px;
}

.close-btn {
  text-align: right;
  margin: -35px -26px 0 -26px;
  width: 125%;
}
.pop-up-heading-text {
  margin-bottom: 20px;
}

.add-team-btn {
  margin: 0 auto;
  width: 213px;
  margin-top: 40px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 20px;
}
.loader-popup {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 110;
  // -ms-transform: translate(-50%, -50%);
  text-align: center;

  .popup-loader-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 64px 47px 48px 47px;
    background: white;
    width: 90vw;
    max-width: 626px;
    min-width: 328px;
    border: 1px solid #e9ecef;
    border-radius: 10px;
    height: 321px;
  }
  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-size: 24px;
    margin-top: 60px;
    font-weight: 400;
  }
  h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
}

// Tablet
@media only screen and (max-width: 768px) {
  .modal-box {
    padding: 50px 70px !important;
  }

  .popup-content-container {
    padding: 50px 26px 0 26px;
    background: white;
    width: 361px;
    // min-width:328px;
    height: 546px;
  }

  .close-btn {
    text-align: right;
    margin: -35px -26px 0 -26px;
    width: 110%;
  }

  .pop-up-heading-text {
    margin-bottom: 8px;
  }

  .add-team-btn {
    width: 100%;
  }
}

// Mobile
@media only screen and (max-width: 576px) {
  .modal-box {
    // width: 350px;
    padding: 40px 30px !important;
  }
  .popup-loader-content {
    padding: 32px 16px !important;
  }
}
