// Kindly press Ctrl+A and then press Ctrl+/ to use the code

// here the typography is defined

@import "../mixins/typegraphyMixin";

// .head1 {
//   @include typography(poppins, 300, 93px, 141px, -1.5px, null); //60 70
// }

// .head2 {
//   @include typography(poppins, 300, 58px, 87px, -0.5px, null); //70px
// }

// .head3 {
//   @include typography(poppins, 400, 46px, 69px, -0.0035em, null);
// }

// .head4 {
//   @include typography(poppins, 400, 32px, 48px, 0.25px, null);
// }

// .head5 {
//   @include typography(poppins, 400, 24px, 36px, 0.005em, null);
// }

// .head6 {
//   @include typography(poppins, 500, 20px, 30px, 0.15px, null);
// }

.s1 {
  @include typography(poppins, 400, 16px, 24px, 0.15px, null);
}

.s2 {
  @include typography(poppins, 500, 14px, 21px, 0.1px, null);
}

.b0-medium {
  @include typography(poppins, 500, 16px, 24px, 0.15px, null);
}

.b1 {
  @include typography(poppins, 400, 16px, 24px, 0.5px, null);
}

.b2 {
  @include typography(poppins, 400, 14px, 21px, 0.25px, null);
}

.button-text {
  @include typography(poppins, 500, 14px, 21px, 1.25px, null);
}

.caption {
  @include typography(poppins, 400, 12px, 18px, 0.4px, null);
}

.overline {
  @include typography(poppins, 400, 12px, 18px, 1.5px, null);
}

// -------------------------------------- NEW TYPOGRAPHY STARTS HERE --------------------------------------

.head1 {
  @include typography(roboto, regular, 36px, 43.2px, normal, null);
}
.head1-medium {
  @include typography(roboto, medium, 36px, 43.2px, normal, null);
}
.head1-semi-bold {
  @include typography(roboto, semi-bold, 36px, 43.2px, normal, null);
}
.head1-bold {
  @include typography(roboto, bold, 36px, 43.2px, normal, null);
}

.head2 {
  @include typography(roboto, regular, 32px, 38.4px, normal, null);
}
.head2-medium {
  @include typography(roboto, medium, 36px, 38.4px, normal, null);
}
.head2-semi-bold {
  @include typography(roboto, semi-bold, 36px, 38.4px, normal, null);
}
.head2-bold {
  @include typography(roboto, bold, 36px, 38.4px, normal, null);
}

.head3 {
  @include typography(roboto, regular, 28px, 33.6px, normal, null);
}
.head3-medium {
  @include typography(roboto, medium, 28px, 33.6px, normal, null);
}
.head3-semi-bold {
  @include typography(roboto, semi-bold, 28px, 33.6px, normal, null);
}
.head3-bold {
  @include typography(roboto, bold, 28px, 33.6px, normal, null);
}

.head4 {
  @include typography(roboto, regular, 25px, 30px, normal, null);
}
.head4-medium {
  @include typography(roboto, medium, 25px, 30px, normal, null);
}
.head4-semi-bold {
  @include typography(roboto, semi-bold, 25px, 30px, normal, null);
}
.head4-bold {
  @include typography(roboto, bold, 25px, 30px, normal, null);
}

.head5 {
  @include typography(roboto, regular, 22px, 26.4px, normal, null);
}
.head5-medium {
  @include typography(roboto, medium, 22px, 26.4px, normal, null);
}
.head5-semi-bold {
  @include typography(roboto, semi-bold, 22px, 26.4px, normal, null);
}
.head5-bold {
  @include typography(roboto, bold, 22px, 26.4px, normal, null);
}

.head6 {
  @include typography(roboto, regular, 20px, 24px, normal, null);
}
.head6-medium {
  @include typography(roboto, medium, 20px, 24px, normal, null);
}
.head6-semi-bold {
  @include typography(roboto, semi-bold, 20px, 24px, normal, null);
}
.head6-bold {
  @include typography(roboto, bold, 20px, 24px, normal, null);
}

.head7 {
  @include typography(roboto, regular, 18px, 21.6px, normal, null);
}
.head7-medium {
  @include typography(roboto, medium, 18px, 21.6px, normal, null);
}
.head7-semi-bold {
  @include typography(roboto, semi-bold, 18px, 21.6px, normal, null);
}
.head7-bold {
  @include typography(roboto, bold, 18px, 21.6px, normal, null);
}

.body1 {
  @include typography(roboto, regular, 16px, 19.2px, normal, null);
}
.body1-medium {
  @include typography(roboto, medium, 16px, 19.2px, normal, null);
}
.body1-semi-bold {
  @include typography(roboto, semi-bold, 16px, 19.2px, normal, null);
}
.body1-bold {
  @include typography(roboto, bold, 16px, 19.2px, normal, null);
}

.body2 {
  @include typography(roboto, regular, 14px, 16.8px, normal, null);
}
.body2-medium {
  @include typography(roboto, medium, 14px, 16.8px, normal, null);
}
.body2-semi-bold {
  @include typography(roboto, semi-bold, 14px, 16.8px, normal, null);
}
.body2-bold {
  @include typography(roboto, bold, 14px, 16.8px, normal, null);
}

.caption1 {
  @include typography(roboto, regular, 12px, 14.4px, normal, null);
}

.caption2 {
  @include typography(roboto, regular, 11px, 13.2px, normal, null);
}

// @mixin typography($fontFamily, $fontSize, $fontWeight, $letterSpacing, $lineHight, $color)

// .pointer {
//   &:hover {
//     cursor: pointer;
//   }
// }

// // .primary-text {
// //   color: $primaryColor;
// // }

// .white-text {
//   color: $whiteColor;
// }
