// css for desktop view
.menu {
  background-color: #240046;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  height: 100vh;
  transform: translateX(-100%);
  position: absolute;
  z-index: 100;
  width: 100%;
  transition: all 450ms ease-in-out;
  // overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.menu-open {
  transform: translateX(0%);
}

.right-side {
  display: flex;
  padding-right: 57px;
  padding-top: 20px;
  width: 300px;
}

.menu-header {
  display: flex;
  padding-top: 20px;
  color: white;
  width: 100%;
  justify-content: space-between;
}
.menu-list {
  position: absolute;
  width: 400px;
  height: 463px;
  color: white;
  margin-left: 122px;
  margin-top: 55px;
}
.menu-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: right;
  padding-right: 57px;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 49px;
}
ul {
  list-style-type: none;
}
li {
  margin-bottom: 20px;
}
li a {
  color: white;
  font-family: "Poppins";
  display: flex;
}
li a:hover {
  text-decoration: none;
  color: white;
}
li a:hover::after {
  content: url("../../icons/Menu/ArrowRight.svg");
  margin-left: 10px;
}

// css for mobile view
.mobile-menu {
  background-color: #240046;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
}
.mobile-menu-list ul {
  padding-left: 24px;
  padding-top: 28px;
}
.mobile-menu-list ul li {
  line-height: 30px;
  font-size: 20px;
}
.mobile-menu-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: right;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 15px;
}
