@import "../style-assets/colors";

.detail-card {
  width: 100%;
  height: 200px;
  background: #ffffff;
  padding: 20px 70px 20px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.detail-card-sm {
  width: 65%;
  height: 200px;
  background: #ffffff;
  padding: 20px 25px 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.billing-card {
  width: 256px;
  height: 406px;

  background: #ffffff;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding-top: 40px;
}

.packages-module {
  background-color: #f8f9fa !important;
  max-width: 1052px;
  margin: 0 auto;
  // min-width: 100% !important;
  // height: 719px;
  // for mobile
  height: 100%;
  // for mobile

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding-top: 30px;
}

.connection-card-container {
  display: flex;
  flex-direction: row;
  // margin: 0 auto;
  gap: 10%;
  // width: 100%;
  // flex-wrap: wrap;
  // align-items: center;
  // align-self: center;
  // justify-content: center;
  // align-items: flex-start;
}

.connection-card {
  width: 100%;
  min-width: 221px;
  // max-width: 222px;
  height: 242px;
  padding-top: 34px;
  // position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;

  background: #ffffff;

  box-sizing: border-box;
  border: 0.88px solid #bfbfbf;
  border-radius: 10px;
}

.connection-card:hover {
  box-shadow: 0px 6px 16px rgba(163, 163, 163, 0.12);
}

.integrations-cards-container {
  // max-width: 1200px;
  border-radius: 10px;
  // margin: 0 auto;
  display: grid;
  // grid-gap: 3%;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(222px, 1fr));
}

.integration-card {
  min-width: 130px;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  background: #ffffff;
  height: 242px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.teams-analytics-container {
  width: 100%;
  // height: 200px;
  background: #ffffff;
  // padding: 20px 70px 20px 70px;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.integration-card:hover {
  box-shadow: 0px 6px 16px rgba(163, 163, 163, 0.12);
}

/* Screen larger than 600px? 2 column */
// @media (min-width: 768px) {
//   .integrations-cards-container {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }

// /* Screen larger than 900px? 3 columns */
// @media (min-width: 992px) {
//   .integrations-cards-container {
//     grid-template-columns: repeat(4, 1fr);
//   }
// }

// @media (min-width: 1400px) {
//   .integrations-cards-container {
//     grid-template-columns: repeat(5, 1fr);
//   }
// }
// // ggreter than 1500px
// @media (min-width: 2100px) {
//   .integrations-cards-container {
//     grid-template-columns: repeat(7, 1fr);
//   }
// }
// /* Screen larger than 1600? 3 columns */
// @media (min-width: 1600px) {
//   .integrations-cards-container {
//     grid-template-columns: repeat(6, 1fr);
//   }
// }
/* Screen larger than 1600? 3 columns */
// @media (min-width: 2100px) {
//   .integrations-cards-container {
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   }
// }

.action-menu-card {
  width: 200px;
  height: 52px;
  position: absolute;
  z-index: 10;
  top: 30px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.integrations-menu-card {
  width: 200px;
  height: 50px;
  position: absolute;
  z-index: 10;
  // top: 30px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

// style={{position:"relative",display:"block",paddingLeft:"30px",top:"0px",height:"73%",width:"100%"}}
.add-members-team-menu-card {
  position: absolute;
  width: 100%;
  z-index: 10;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(163, 163, 163, 0.12);
  // border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  // left: -50%;
  // overflow: hidden;s
  height: 322px;
}

.filter-menu-card,
.filter-sub-menu-card-container {
  width: 250px;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.filter-menu-card {
  top: 240px;
  left: 200px;
  height: 116px;
}

.filter-sub-menu-card-container {
  flex-direction: column;
  justify-content: flex-start;

  overflow: hidden;
  // padding: 10px 10px 0 10px;
  height: auto;
  max-height: 400px;
}

.filter-sub-menu-card {
  overflow-y: scroll;
}

.card-connector-image {
  // margin-top: 15px;
  margin-bottom: 14px;
  width: 50px;
  height: 50px;
}

.card-action-button {
  // position: relative;
  // right: 0;
  width: 100%;
  // border: 1px solid;
  // margin-left: auto;
  display: flex;
  justify-content: right;
  // margin-top: 10px;
  margin-right: 24px;
  margin-top: 12px;
}

.billing-cards {
  margin-right: 80px;
}

.modal-dialog {
  min-width: 1052px;
  // min-width: 100%;
}

@media only screen and (max-width: 1024px) {
  .modal-dialog {
    min-width: 100% !important;
    width: 100%;
    // min-width: 100%;
  }
}

// Tablet
@media only screen and (max-width: 768px) {
  .connection-card {
    height: 233px;
  }

  // .card-action-button {
  //   margin-top: 5px;
  //   padding-right: 15px;
  // }
  // .card-connector-image {
  //   padding-top: 0;
  //   margin: 4px 0;
  //   width: 44px;
  //   height: 44px;
  // }
  // .card-connector-name {
  //   padding-top: 14px;
  // }
  // .card-connection-status {
  //   padding-top: 11px;
  // }

  .modal-dialog {
    min-width: 600px !important;
    width: 100%;
    // min-width: 100%;
  }

  .billing-card {
    width: 227px;
    margin: 0 10px;
  }
}

// MOBILE
@media only screen and (max-width: 768px) {
  .integration-card {
    height: 200px;
  }

  .card-action-button {
    margin-top: 5px;
    padding-right: 15px;
  }

  .card-connector-image {
    padding-top: 0;
    margin: 4px 0;
    width: 44px;
    height: 44px;
  }

  .card-connector-name {
    padding-top: 14px;
  }

  .card-connection-status {
    padding-top: 11px;
  }
}

@media only screen and (max-width: 425px) {
  .billing-cards {
    margin-bottom: "80px";
  }

  .modal-dialog {
    margin: 0 !important;
    min-width: 300px !important;
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .connection-card {
    width: 152px;
    height: 200px;
  }

  .card-action-button {
    // padding-top: 0px;
    margin-top: -5px;
    padding-right: 10px;
  }

  // .card-three-dots {
  //   padding-top: 12px;
  //   padding-right: 14px;
  //   width: 27%;
  //   // height: 24px;
  // }
  .card-connector-image {
    // padding-top: 8px;
    // width: 27%;
  }

  .card-connector-name {
    padding-top: 14px;
  }

  .card-connection-status {
    padding-top: 7px;
  }

  .card-status {
    font-size: 14px;
    letter-spacing: 0.25px;
  }
}

// @media only screen and (max-width: 375px) {
//   .page-body-with-min-height {
//     padding: 16px 20px 72px 20px;
//   }
//   .connection-card-gap {
//     row-gap: 16px;
//     column-gap: 16px;
//   }
// }
@media only screen and (max-width: 378px) {
  .connection-card {
    width: 150px;
    height: 195px;
  }

  .card-connector-image {
    padding-top: 8px;
    width: 26%;
  }

  .card-connector-name {
    padding-top: 18px;
  }

  .card-connection-status {
    padding-top: 7px;
  }

  .connection-card-status-icon {
    width: 9px;
    height: 9px;
  }
}

@media only screen and (max-width: 374px) {
  .connection-card-container {
    // display: flex;
    // flex-direction: row;
    // margin: 0 auto;
    gap: 3%;
    // width: 100%;
    // flex-wrap: nowrap;
    // align-items: center;
    // align-self: center;
    // justify-content: center;
    // align-items: flex-start;
  }

  .connection-card {
    min-width: 140px;
    // height: auto;
  }
}
