@import "../style-assets/colors";

.input-field {
  width: 375px;
  height: 45px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 20px;
  color: $grey8 !important;
}

.input-field:focus {
  outline: none !important;
  color: $grey3 !important;
}

.input-field-full {
  width: 100%;
  height: 48px;
  // background: #f8f9fa;
  background: #ffffff;

  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 12px;
  // color: $grey8 !important;
}

.read-only {
  color: $grey6;
}

.input-field-full:focus {
  outline: none !important;
  color: $grey3 !important;
}

.input-field-full-icon {
  width: 93%;
  height: 45px;
  // background: #f8f9fa;
  border: 1px solid #dedede;
  border-right: none;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
  padding-left: 20px;
  color: $grey8 !important;
}

.input-field-full-icon:focus {
  outline: none !important;
  color: $grey3 !important;
}

.input-field-full-list {
  width: 264px;
  height: 45px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  // border-radius: 10px;

  color: $grey8 !important;
  text-align: center !important;
  padding: 10px;
}

.input-field-full-list:hover {
  background: #adb5bd;
  cursor: pointer;
}

.input-field-teams-list {
  width: 100%;
  height: 45px;
  // background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  // border-radius: 10px;

  color: $grey8 !important;
  text-align: center;
  padding: 10px;
}

.input-field-teams-list:hover {
  background: rgb(235, 235, 235);
  cursor: pointer;
}

.input-field-icon {
  width: 350px;
  height: 45px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;
  border-right: none;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
  padding-left: 20px;
  color: $grey8 !important;
}

.input-field-icon:focus {
  outline: none !important;
  color: $grey8 !important;
}

.icon {
  width: 40px;
  height: 45px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;
  border-left: none;
  box-sizing: border-box;
  border-radius: 0px 10px 10px 0px;
  // padding-right: 15px;
  display: flex;
  align-items: center;
}

.add-members-cohort {
  position: relative;
  width: 100%;

  .add-members-cohort-icon {
    width: 40px;
    height: 45px;
    background: none;
    position: absolute;
    box-sizing: border-box;
    top: 70%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 0px 10px 10px 0px;
  }
}

.add-members-wrapper {
  position: relative;
  width: 100%;

  .add-members-search-icon {
    // height: 1.5rem;
    // width: 1.5rem;
    width: 40px;
    height: 45px;
    // background-color: red;
    background: none;
    // padding: 4px;
    position: absolute;
    // border: 1px solid #e9ecef;
    box-sizing: border-box;
    top: 70%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 0px 10px 10px 0px;
  }

  // box-sizing: border-box;
  // border-left: none;

  .add-members-input {
    // height: 50px;
    box-sizing: border-box;
    padding-left: 1.5rem;
  }
}

.search-icon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.input-field-icon-sm {
  width: 190px;
  height: 45px;
  background: #ffffff;

  border: 1px solid #e9ecef;
  border-right: none;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
  padding-left: 20px;
  color: $grey8 !important;
}

.stripe-input-field-full {
  width: 100%;
  height: 45px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 20px;
  color: $grey8 !important;
  align-items: center;
  justify-content: center;
}

.stripe-input-field-full:focus {
  outline: none !important;
  color: $grey3 !important;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.input-field-icon-list {
  width: 85%;
  height: 45px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;
  border-right: none;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
  padding-left: 20px;
  color: $grey8 !important;
}

.input-field-icon-list:focus {
  outline: none !important;
  color: $grey3 !important;
}

.input-field-checkbox {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
}

.input-field-apply-coupon {
  width: 279px;
  height: 48px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 20px;
  color: $grey8 !important;
}

.input-field-apply-coupon-input {
  width: 279px;
  height: 48px;
  background: #f8f9fa;
  border: none;
}

.input-field-apply-coupon-button {
  width: 74px;
  height: 40px;
  background: #7b2cbf;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 5px;
  border: none;
}

.input-field-apply-coupon-button:disabled {
  background: #adb5bd;
}

.input-field-apply-coupon:focus {
  outline: none !important;
  color: $grey3 !important;
}

.input-field-apply-coupon-input:focus {
  outline: none !important;
  color: $grey3 !important;
}

.input-field-full-list-address {
  // // width: 256px;
  // // height: 45px;
  background-color: #f8f9fa !important;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;

  color: $grey8 !important;
  // text-align: center !important;
  padding: 10px;
}

.grey9-placeHolderColor::placeholder {
  color: #212529;
}
.grey8-placeHolderColor::placeholder {
  color: #343a40;
}

.grey5-placeHolderColor::placeholder {
  color: #adb5bd;
}

.grey6-placeHolderColor::placeholder {
  color: $grey6;
}

// input::placeholder {
//   color: white;
// }

.header-search-container {
  // position: relative;
  // top: 50%;
  // left: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s;
  width: 50px;
  height: 50px;
  background: white;
  box-sizing: border-box;
  border-radius: 25px;
  border: 4px solid white;
  padding: 5px;

  &:hover {
    width: 200px;
    cursor: pointer;
  }

  &:hover input {
    display: block;
  }

  &:hover .search-icon-expandable {
    // background: #07051a;
    color: white;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 20px;
  }

  .search-icon-expandable {
    box-sizing: border-box;
    padding: 10px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #07051a;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
  }
}

.disable-default-focus:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset; /* Background color */
  -webkit-text-fill-color: #333; /* Text color */
}

.disable-default-focus:focus {
  outline: none;
  box-shadow: none;
  // background-color: #f0f0f0; /* Grey color */
  color: #333;
}

.disable-default-focus {
  // background-color: #f0f0f0;
  color: #333;
}

// .search-icon {
//   pointer-events: none;
// }

.header-search-container:hover {
  width: 200px;
  cursor: pointer;
}

.header-search-container:hover .explandable-search {
  display: block;
}

.header-search-container:hover .search-icon {
  background: #07051a;
  color: white;
}

.input-field-full-list-address:hover {
  background-color: #e9ecef !important;
  cursor: pointer;
}

input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 375px) {
  .input-field-full,
  .input-field-full-icon,
  .icon {
    height: 40px;
  }
}

.search {
  display: inline-block;
  position: relative;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  margin: 0px 8px 7px 0px;
  padding: 7px 9px 0px 9px;
  border: 2px solid #343a40;
  border-radius: 25px;
  transition: all 300ms ease;
  cursor: text;

  input {
    width: 100%;
    border: none;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 15px;
    color: inherit;
    background: transparent;
    outline-width: 0px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    right: -4px;
    top: 16px;
    background: #343a40;
    border-radius: 3px;
    transform: rotate(-50deg);
    transition: all 200ms ease;
  }

  &.active {
    width: 40vw;
    height: 40px;
    margin-right: 0px;
    border-radius: 2px;
    border: 1px solid #e9ecef;
    background: #f8f9fa;

    &:after {
      height: 0px;
    }
  }
}
