.invite-teammate {
  width: 818px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  //   padding: 0px 60px;
  background: #ffffff;
  /* Grey / Grey 2 */

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  /* Drop Shadow */

  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
