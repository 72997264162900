.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 25px;
  &.disabled {
    pointer-events: none;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #adb5bd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &.disabled {
    background-color: #dee2e6;
    // background-color: black !important;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.disabled:before {
  background-color: #adb5bd;
}

input:checked + .slider {
  background-color: #7b2cbf;
}

input:checked + .slider.disabled {
  background-color: #dee2e6;
}

input:checked + .slider.disabled:before {
  background-color: #adb5bd;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7b2cbf;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
