// Sidebar.scss

.sidebar-parent {
  a {
    text-decoration: none;
  }
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  transition: all 0.25s ease-in-out;
  margin: 4px 12px;
  border-radius: 4px;
  height: 52px;

  .item-link {
    color: white;
    // font-weight: bold;
    text-decoration: none;
  }
  .activeItem {
    color: #27005d !important;
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 10px;
  }

  // When the 'collapsed' class is added, change the padding and margin
  &.collapsed {
    padding: 8px 16px;
    margin: 2px 9px;
  }
}
.sidebar-logo-container {
  transition: width 0.3s ease; // Smooth transition for width change
  overflow: hidden; // Hide overflowing content
}

.sidebar-logo-container svg {
  width: 150px; // Keep the logo size fixed
  height: auto; // Maintain aspect ratio
}
