@import "../../styles/style-assets/typography";

.rct-node-leaf {
  margin-bottom: 0;
}

.rct-text {
  height: 36px;
}

.rct-node-icon {
  display: none;
}
.rct-node-parent {
  margin-bottom: 0;
}

.rct-title {
  @extend .body1;
}
