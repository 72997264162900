.survey-stack {
  position: relative;
  // max-width: 500px;
  margin: 0 auto;
  height: 100%; /* Ensure the container is tall enough */
  // margin-top: 50px;
}

.survey {
  position: absolute;
  width: 100%;
  // background: #fff0f0;
  border-radius: 20px;
  padding: 20px;
  // border: 1.16px solid #dcdcdc;
  box-shadow:
    0px 13.97px 18.63px -4.66px rgba(36, 36, 36, 0.08),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px); /* Initial position before entering */
  transition:
    transform 350ms ease,
    opacity 350ms ease,
    visibility 0s linear 350ms;
}

.survey-stack.open .survey {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.survey-stack.closing .survey {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 150ms ease,
    visibility 0s linear 150ms;
  transform: none; /* Remove the transform transition on close */
}

.survey-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.survey-question {
  font-weight: bold;
  margin-bottom: 20px;
}

.survey-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.emoji-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.emoji-option .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;
}

.emoji-option.selected .emoji {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.emoji-label {
  color: #7e57c2;
  font-weight: bold;
  font-size: 14px;
}

.survey-actions {
  display: flex;
  justify-content: space-between;
}
