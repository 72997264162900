@import "./main";

.App {
  text-align: center;
}

// To avoid blue highlight on double click
.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

.disableEvents {
  pointer-events: none;
}

.disableField {
  pointer-events: none;
}

.hideScrollBar {
  // -ms-overflow-style: none; /* for Internet Explorer, Edge */
  // scrollbar-width: none; /* for Firefox */
  // overflow-y: scroll;
  // overflow-x: scroll;
  // scrollbar-width: none;
  // overflow-y: auto;
}

.hideScrollBar::-webkit-scrollbar {
  height: 4px;
  // display: none; /* for Chrome, Safari, and Opera */
}
