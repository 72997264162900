.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 25%;
  // min-height: 100vh;
  // position: relative;
  padding-right: 25%;
}

.signup-fields {
  width: 560px;
  // width: 100%;
  height: fit-content;
  text-align: left;
}

//------------------------ SUBSCRIPTION CSS

// .subscription-card-list {

// }
.card-input-element {
  padding: 10px 0;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #0054dc !important;
  margin-bottom: 30px;
  font-variant: Helvetica Now Text !important;
  font-size: 20px !important;
  // font         : Helvetica Now Text, normal, 20px, 20px, -0.015em, null !important;

  // ::placeholder {
  //   color: $mischka;
  // }
}

.cvc-icon {
  ::after {
    content: "";
    display: block;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 10px;
    height: 17px;
    width: 25px;
    // background-image : url("./../../../icons/basic/cvc_card.svg");
    background-repeat: no-repeat;
  }

  // pos
}

.subscription-package-modal-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.32);
}

.subscription-package-modal {
  position: relative;
  margin: 2.5% auto;
  height: 760px;
  width: 80%;
  max-width: 1000px;
  background-color: #ffffff;
  box-shadow: 6px 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.subscription-modal-closebtn {
  position: absolute;
  top: 25px;
  right: 25px;
  height: 20px;
  width: 20px;
  border: 1px solid #000000;
  border-radius: 100%;
  cursor: pointer;
}

.subscription-modal-closebtn::before {
  content: "";
  display: block;
  // padding          : 10px;
  height: 100%;
  width: 100%;
  // background-image: url("./../../../icons/basic/cross.svg");
  background-size: 10px 10px;
  background-position: center;
  background-repeat: no-repeat;
}

.package-card {
  height: 505px;
  position: relative;
  cursor: pointer;
}

.package-card-header {
  position: absolute;
  width: 100%;
  height: 168px;
  background-color: #ffffff;
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  // z-index         : 999999999;
}

.package-card-detail {
  z-index: -1;
  width: 95%;
  height: 100%;
  background-color: #ffffff;
  margin: auto;
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding-top: 200px;
}

// .selected .package-card-header {
//   background-color: $navy-blue;
// }

.selected .package-card-detail {
  z-index: -1;
  width: 95%;
  height: 100%;
  // background-color: $navy-blue;
  margin: auto;
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  // position        : relative;
}

.selected .selected-package-card {
  text-align: center;
  color: #00d69a;
  position: absolute;
  height: 42px;
  width: 152px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -21px;
  background-color: #ffffff;
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}

.unselected .selected-package-card {
  text-align: center;
  color: white;
  position: absolute;
  height: 42px;
  width: 152px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -21px;
  background-color: #0054dc;
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}

.signup-header {
  padding-top: 109px;
  text-align: left;
}
.header-logo {
  width: 351px;
  height: 69px;
}

.process-logo {
  width: 558px;
  height: 64px;
}

.account-activated {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding-left: 25%;
  // min-height: 100vh;
  // position: relative;
  // padding-right: 25%;
}

.account-activated-message {
  padding-top: 30px;
  padding-bottom: 50px;
}

.MuiCircularProgress-root {
  color: black;
}

.signup-flow-headers {
  padding-top: 100px;
}

.not-found-header {
  padding-top: 150px;
}

.plan-modal {
  // width: 100% !important;
}

// Tablet
@media only screen and (max-width: 768px) {
  .header-logo {
    width: 176px;
    height: 35px;
  }

  .signup {
    padding-left: 0;
    // min-height: 100vh;
    margin: 0 138px;
    padding-right: 0;
  }

  .signup-fields {
    min-width: 358px;
    width: 100%;
    // width: 100%;
    height: fit-content;
    text-align: left;
  }
  .process-logo {
    width: 100%;
    min-width: 370px;
    height: 64px;
  }

  .account-activated {
    margin: 0 138px;
  }

  .account-activated-message {
    padding-bottom: 48px;
  }
  .signup-header {
    padding-top: 80px;
  }
  .not-found-header {
    padding-top: 100px;
  }
}

.password-description {
  display: none;
}

// MOBILE
@media only screen and (min-width: 720px) {
  .password-description {
    display: block;
  }
}

@media only screen and (max-width: 425px) {
  .account-activated {
    margin: 0;
  }

  .signup-header {
    padding-top: 40px;
  }

  .not-found-header {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 425px) {
  .signup {
    padding-left: 24px;
    // min-height: 100vh;
    // padding: 0 24px;
    margin: 0;
    padding-right: 24px;
  }

  .signup-input-field {
    height: 10px !important;
  }

  .signup-fields {
    // width: auto;
    // display: table;
    min-width: 270px;
    width: 100%;
    // width: 100px;
  }
  .signup-flow-headers {
    padding-top: 56px;
  }
}
// @media only screen and (max-width: 328px) {
// 	.signup {
// 		padding-left: 0;
// 		// min-height: 100vh;
// 		// padding: 0 24px;
// 		margin: 0;
// 		padding-right: 0;
// 	}

// 	.signup-fields {
// 		width: auto;
// 		min-width: 0;
// 		display: table;
// 	}

// }
