.setting-box {
  width: 864px;
  height: fit-content;

  background: #ffffff;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;

  padding: 24px 16px 0px 16px;
  text-align: start;

  display: flex;
  flex-flow: column;
}
