@import "../style-assets/colors";

.tab {
  width: 150px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  cursor: pointer;

  border-bottom: 4px #7b2cbf solid;
}

.tab:disabled {
  border: none;
}

.tab-sm {
  width: 174px;
  // height: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  // margin-right: 10px;
  cursor: pointer;

  // border-bottom: 4px #7b2cbf solid;
}

.tab-sm:disabled {
  border: none;
}

.table {
  display: table;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell;
}

.row,
.cell {
  padding: 10px;
}
