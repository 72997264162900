//Brand Colors

$primary: #7b2cbf, 100%;
$secondary: #240046, 100%;
$red: #ee6055, 100%;
$green: #7ae582, 100%;

//Grey Shades

$grey0: #ffffff, 100%;
$grey1: #f8f9fa, 100%;
$grey2: #e9ecef, 100%;
$grey3: #dee2e6, 100%;
$grey4: #ced4da, 100%;
$grey5: #adb5bd, 100%;
$grey6: #6c757d, 100%;
$grey7: #495057, 100%;
$grey8: #343a40, 100%;
$grey9: #212529, 100%;

// color classes

.grey0 {
  color: #ffffff !important ;
}

.grey1 {
  color: #f8f9fa !important ;
}

.grey2 {
  color: #e9ecef !important ;
}

.grey3 {
  color: #dee2e6 !important ;
}

.grey4 {
  color: #ced4da !important ;
}

.grey5 {
  color: #adb5bd !important ;
}

.grey6 {
  color: #6c757d !important ;
}

.grey7 {
  color: #495057 !important ;
}

.grey8 {
  color: #343a40 !important ;
}

.grey9 {
  color: #212529 !important ;
}

.primary-color {
  color: #9300ff !important;
}

.secondary-color {
  color: #240046 !important;
}
