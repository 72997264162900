@import "../style-assets/colors";

.side-menu-box {
  width: 70%;
  max-width: 1052px;
  height: 100vh;
  position: fixed;
  right: -100%;
  top: 0;
  opacity: 0;
  z-index: -1;
  background: #ffffff;
  overflow-x: hidden;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  text-align: start;
  margin-left: auto;
  display: flex;
  flex-flow: column;
  // transition: 850ms;
  transition: 0.5s all ease;
  padding: 24px 48px 40px 48px;
}

.side-menu-box.open {
  // width: 868px;
  right: 0;
  transition: 350ms;
  opacity: 1;
  z-index: 50;
  // transition: 0.5s all ease;
}

.MuiPaper-root.main-drawer {
  width: 70%;
  max-width: 1052px;
  padding: 24px 48px 40px 48px;
}

.flash-notification-menu-container {
  position: absolute;
  top: 90;
  right: 50;
  z-index: -10;
  transition: z-index 0.5s step-end;
}

.flash-notification-menu-container.open {
  z-index: 10;
  transition: z-index 0.5s step-start;
}

// .flash-menu-box {
//   width: 100%;
//   max-width: 508px;
//   width: 60vw;
//   height: 60vh;
//   // position: absolute;
//   // left: 0;
//   // top: -100%;
//   // display: none;
//   opacity: 0;
//   // z-index: 2000;
//   background: #ffffff;
//   overflow-x: hidden;
//   border: 1px solid #e9ecef;
//   box-sizing: border-box;
//   text-align: start;
//   margin-left: auto;
//   display: flex;
//   flex-flow: column;
//   // transition: 850ms;
//   // transition: 0.5s all ease;

//   // padding: 24px 72px 40px 72px;
//   box-shadow: 0px 6px 16px rgba(163, 163, 163, 0.12);
//   transition: 100ms;
//   // display: none;

.emoji-reaction {
  // width: 48px;
  // height: 48px;
  font-size: 28px;
  cursor: pointer;

  &:hover {
    // background-color: black !important;
    transition: 100ms;
    transform: scale(1.2);
  }

  // .notification-activity{
  //   white-space: nowrap;
  // }
}
// }

// .flash-menu-box.open {
//   // width: 868px;
//   display: flex;
//   top: 0;
//   // display: flex;
//   transition: 350ms;
//   opacity: 1;
//   z-index: 50;
//   // transition: 0.5s all ease;
// }

// .flash-survey {
//   opacity: 0;
// }
// .flash-survey.open {
//   // width: 868px;
//   display: flex;
//   top: 0;
//   // display: flex;
//   transition: 350ms;
//   opacity: 1;
//   z-index: 50;
//   // transition: 0.5s all ease;
// }

.flash-menu-box,
.flash-survey {
  width: 100%;
  max-width: 508px;
  width: 60vw;
  // height: 60vh;
  max-height: 60vh;
  opacity: 0;
  visibility: hidden;
  // background: #ffffff;
  overflow-x: hidden;

  box-sizing: border-box;
  text-align: start;
  margin-left: auto;
  display: flex;
  flex-flow: column;
  // box-shadow: 0px 6px 16px rgba(163, 163, 163, 0.12);
  border-radius: 20px;
  transition:
    opacity 350ms ease,
    visibility 0s linear 350ms;
  z-index: 50;
}

.flash-menu-box.open,
.flash-survey.open {
  opacity: 1;
  visibility: visible;
  transition:
    opacity 350ms ease,
    visibility 0s;
}

/* Handle visibility after transition for the closing state */
// .flash-menu-box:not(.open),
// .flash-survey:not(.open) {
//   opacity: 0;
//   visibility: hidden;
//   transition:
//     opacity 350ms ease,
//     visibility 0s linear 350ms;
// }

.side-menu-box-big {
  width: 868px;
  height: 1274px;

  background: #ffffff;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  text-align: start;

  display: flex;
  flex-flow: column;
  padding: 40px 32px;
}

.side-menu-table {
  width: 90%;
  height: fit-content;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  flex-flow: column;
  text-align: start;
}

.integration-menu-buttons-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}

.integration-menu-buttons-container-mobile {
  display: none;
}

// .sync-menu-options-container{
//   height:auto;
//   max-height: 150px;
//   padding:16px 0px;
//   background-color:#F8F9FA;
//   border: 1px solid #DEE2E6;
//   border-top: none;
//   border-radius:10px;
//   border-top-left-radius: 0px;
//   border-top-right-radius: 0px;
// }
.sync-menu-options-container {
  height: auto;
  max-height: 150px;
  padding: 16px 25px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.side-menu-table-cell {
  min-width: 210px;
  // background: black;
}

.sync-menu-search-field,
.filter-search-container {
  // overflow:hidden;
  min-height: 72px;
  border: 1px solid #e9ecef;
  color: #7b2cbf;
  border-radius: 10px;
  align-items: center;
  padding: 8px 24px 8px 24px;
  margin-bottom: 24px;
}

.filter-search-container {
  background-color: #ffffff;
}

.bottom-menu-box {
  width: 100%;
  height: 0;
  position: fixed;
  bottom: -50%;
  left: 0;
  opacity: 0;
  z-index: -1;
  background: #ffffff;
  overflow-x: hidden;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  text-align: start;
  margin-left: auto;
  display: flex;
  flex-flow: column;
  // transition: 850ms;
  transition: all 0.4s;
  padding: 32px 24px;
}

.bottom-menu-box.open {
  // width: 868px;
  bottom: 0;
  height: 60vh;
  // transition: 350ms;
  opacity: 1;
  z-index: 50;
  // transition: 0.5s all ease;
}

.top-border {
  min-width: 100%;
  // margin-top: 12px;
  border-top: 2px solid #dee2e6;
}

// Tablet
@media only screen and (max-width: 768px) {
  .side-menu-box {
    width: 100%;
    // position: fixed;
    // right: -100%;
    // text-align: start;
    // margin-left: auto;
    padding: 24px 32px 40px 32px;
  }

  .integration-menu-buttons-container-mobile {
    display: block;
    margin-top: 35px;
  }

  .top-border {
    min-width: calc(120% + 64px);
    margin-left: -50px;
    margin-top: 12px;
    border-top: 1px solid #dee2e6;
  }

  .integration-menu-buttons {
    display: none !important;
  }
}

// MOBILE

@media only screen and (max-width: 425px) {
  .side-menu-box {
    padding: 24px 24px 40px 24px;
  }

  .sync-menu-options-container {
    max-height: 163px;
    padding: 16px 8px;
  }

  .b2-mobile {
    // margin-top: 30px;
  }

  .sync-menu-search-field {
    padding: 8px;
  }

  .side-menu-table-cell {
    min-width: 0px;
    // background: black;
  }
}
