@import "../style-assets/colors";

.header {
  width: 100%;
  height: 122px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: white;
  justify-content: center;
  filter: drop-shadow(0px 6px 16px rgba(163, 163, 163, 0.12));

  .title {
    width: 100%;
    // height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 70px;
  }

  .tags {
    padding-left: 70px;
    width: 750px;
    display: flex;
    // justify-content: space-between;
  }

  .identity-provider-alert {
    background-color: #ee6055 !important;
    padding-left: 60px !important;
    height: 92px !important;
    color: white !important;
    margin-bottom: 0px;
  }

  .header-list-icon {
    display: none;
  }

  .user-icon-header {
    width: auto;
    display: block;
  }

  // TABLET
  @media only screen and (max-width: 768px) {
    .header-list-icon {
      display: block;
    }
    .title {
      padding: 0px 40px;
    }
    .user-icon-header {
      display: none !important;
      // opacity: 0;
      // cursor: none;
    }
  }
}
.TeamHeader {
  width: 100%;
  height: 122px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: #ffffff;
  justify-content: center;
  border: none !important;
  // filter: drop-shadow(0px 6px 16px rgba(163, 163, 163, 0.12));

  .title {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 70px;
  }
  .newHeaderContainer {
    width: 100%;
    // height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 42px;
    margin-bottom: 24px;
  }

  .tags {
    padding-left: 70px;
    width: 750px;
    display: flex;
    // justify-content: space-between;
  }

  .identity-provider-alert {
    background-color: #ee6055 !important;
    padding-left: 60px !important;
    height: 92px !important;
    color: white !important;
    margin-bottom: 0px;
  }

  .header-list-icon {
    display: none;
  }

  .user-icon-header {
    display: block;
  }

  // TABLET
  @media only screen and (max-width: 768px) {
    .header-list-icon {
      display: block;
    }
    .title {
      padding: 0px 40px;
    }
    .user-icon-header {
      display: none !important;
      // opacity: 0;
      // cursor: none;
    }
  }
}
