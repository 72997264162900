// @import url(https://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:300italic,700italic,400,300&display=swap);
// @import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
// @import url(https://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:300italic,700italic,400,300&display=swap);
// @import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);

@mixin typography(
  $fontFamily,
  $fontWeight,
  $fontSize,
  $lineHight,
  $letterSpacing,
  $color
) {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHight;
  letter-spacing: $letterSpacing;
  @if $color == null {
    color: #000000;
  } @else {
    color: $color;
  }
  @if $fontWeight == "regular" {
    font-weight: 400;
  } @else if $fontWeight == "medium" {
    font-weight: 500;
  } @else if $fontWeight == "semi-bold" {
    font-weight: 600;
  } @else if $fontWeight == "bold" {
    font-weight: 700;
  } @else {
    font-weight: $fontWeight;
  }
}
