.page-team {
  background-color: #f8f9fa;
  padding: 40px 70px 245px 70px;
  display: flex;
  text-align: left;
}
.search-div {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
.teams-table {
  padding-top: 20px;
}
.team-avatar-height {
  max-width: 198;
  height: 210px;
}
.fieldW {
  width: "76%";
}

.teams-menu-container {
  // width: 100%;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.teams-menu-container {
  // max-width: 1100px;
  flex-direction: column;
  justify-content: flex-start;
  // left: -50%;
  // overflow: hidden;
  // padding: 10px 10px 0 10px;
  // height: 400px;
}

@media (max-width: "426px") {
  .page-team {
    background-color: #f8f9fa;
    padding: 20px 24px 0px 24px;
    display: flex;
    text-align: left;
  }
  .create-team-margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .team-avatar-height {
    height: 130px;
    margin-top: 40px;
  }
}
