@mixin loader($width: 52px, $inner-width: 40px, $innermost-width: 28px) {
  width: $width;
  height: $width;
  border-radius: 50%;
  display: inline-block;
  z-index: 30;
  position: relative;
  border: 3px solid;
  border-color: #7b2cbf #7b2cbf transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after,
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #240046 #240046;
    width: $inner-width;
    height: $inner-width;
    border-radius: 50%;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }

  &::before {
    width: $innermost-width;
    height: $innermost-width;
    border-color: #7b2cbf #7b2cbf transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
}

// Using the mixin for full-loader
.full-loader {
  @include loader();
}

// Using the mixin with smaller size
.small-loader {
  @include loader(26px, 18px, 12px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.dots-5 {
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: d5 1s infinite linear alternate;
}
@keyframes d5 {
  0% {
    box-shadow:
      16px 0 #6d27aa,
      -16px 0 #ffffff;
    background: #6d27aa;
  }
  33% {
    box-shadow:
      16px 0 #6d27aa,
      -16px 0 #ffffff;
    background: #ffffff;
  }
  66% {
    box-shadow:
      16px 0 #ffffff,
      -16px 0 #6d27aa;
    background: #ffffff;
  }
  100% {
    box-shadow:
      16px 0 #ffffff,
      -16px 0 #6d27aa;
    background: #6d27aa;
  }
}
