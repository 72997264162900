.page-body,
.page-body-integrations {
  min-height: 100vh;
  // background-color: #ffffff;
  background-color: #f8f9fa;
  padding: 0px 44px 0px 44px;
  display: flex;
  text-align: left;
}

.page-body-new {
  @extend .page-body;
  // padding-top: 32px;
  padding-bottom: 32px;
  min-height: 0 !important;
  background-color: #fff; // Black background color
}
.page-body-integrations {
  padding: 40px 70px 0px 70px !important;
}

.welcome-tour {
  width: 1177px;
  height: 740px;
  padding-top: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

// TABLET
@media only screen and (max-width: 768px) {
  .page-body {
    padding: 50px 40px 0px 40px;
  }
  .page-body-integrations {
    padding: 50px 40px 0px 40px !important;
  }
}

// MOBILE
@media only screen and (max-width: 425px) {
  .page-body {
    padding: 42px 24px 0px 24px;
  }
  .page-body-integrations {
    padding: 42px 24px 0px 24px !important;
  }
}
