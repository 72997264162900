@import "../style-assets/colors";

//Buttons-General

.button-filled-sm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 80px;
  height: 40px;

  background: #7b2cbf;
  border-radius: 10px;

  &:hover {
    background: #602295;
  }
}

.button-hollow-sm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 80px;
  height: 40px;

  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.button-filled-md {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.5px 15px;
  cursor: pointer;

  width: fit-content;
  height: 48px;

  background: #7b2cbf;
  border-radius: 10px;

  &:hover {
    background: #602295;
  }
}

.button-hollow-md {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.5px 15px;
  cursor: pointer;

  width: 94px;
  height: 48px;

  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.button-filled-lg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 10.5px 15px;
  padding: 8px 16px;
  cursor: pointer;
  // color: white;
  width: fit-content;
  // height: 35px;
  border: none;
  background: #7b2cbf;
  border-radius: 10px;

  &:hover {
    background: #602295;
  }
}

.button-filled-full {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 15px;
  cursor: pointer;
  border: none;
  min-width: 110px;
  width: 100%;
  height: 48px;

  background: #7b2cbf;
  // color: $grey1;
  border-radius: 10px;

  &:hover {
    background: #602295;
  }
}

.button-filled-full:disabled {
  background: #adb5bd;
}
.button-filled-lg:disabled {
  background: #adb5bd !important;
}

.button-hollow-lg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 15px;
  cursor: pointer;

  width: 100%;
  height: 48px;

  // background: #f8f9fa;

  // border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.button-icon-filled-sm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 106px;
  height: 40px;

  background: #7b2cbf;
  border-radius: 10px;

  &:hover {
    background: #602295;
  }
}

.button-icon-hollow-sm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 106px;
  height: 40px;

  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.button-icon-filled-md {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 124px;
  height: 40px;

  background: #7b2cbf;
  border-radius: 10px;

  &:hover {
    background: #602295;
  }
}

.button-icon-hollow-md {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 124px;
  height: 40px;

  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.button-icon-filled-lg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 141px;
  height: 40px;

  background: #7b2cbf;
  border-radius: 10px;

  &:hover {
    background: #602295;
  }
}

.button-icon-hollow-lg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 141px;
  height: 40px;

  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

// -------------------------

.filter-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 139px;
  height: 40px;

  background: #7b2cbf;
  border-radius: 10px;

  &:hover {
    background: #602295;
  }
}

.filter-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;

  position: absolute;
  width: 250px;
  height: 40px;
  cursor: pointer;

  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;
}

.filter-tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.5px 15px;
  cursor: pointer;

  width: 267px;
  height: fit-content;

  background: #f8f9fa;

  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 10px;

  margin: 0px 20px;
}

.button-plan-filled-md {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.5px 15px;
  color: white;

  width: fit-content;
  height: 48px;

  background: #7b2cbf;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);

  &:hover {
    background: #602295;
  }
}

.action-button {
  position: absolute;

  margin-top: 15px;
  width: 200px;
  height: 90px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;

  background: #ffffff;
  /* Drop Shadow */

  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

// @media only screen and (max-width: 425px) {
// 	.button-filled-full {
// 		height:
// 	}
// }
