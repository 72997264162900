.skeleton {
  margin: 10px 0;
}

.skeleton-wrapper {
  width: 100%;
  position: relative;
}

.skeleton .card {
  width: 100%;
  min-height: 160px;
  background: #d2dbe2;
  border-radius: 7px;
}

.skeleton .text-lg {
  width: 100%;
  margin-top: 10px;
  background: #d2dbe2;
  border-radius: 7px;
  min-height: 10px;
}

.skeleton .text-md {
  width: 70%;
  margin-top: 10px;
  background: #d2dbe2;
  border-radius: 7px;
  min-height: 10px;
}

.skeleton .text-sm {
  width: 40%;
  margin-top: 10px;
  background: #d2dbe2;
  border-radius: 7px;
  min-height: 11px;
}

.graph-shimmer {
  width: 60%;
  height: 100%;
  opacity: 0.2;
  background: rgba(187, 187, 187, 0.966);
  transform: skewX(-20deg);
  animation: graphloader 1s linear infinite;
}

.shimmer-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.shimmer {
  width: 50%;
  height: 100%;
  opacity: 0.2;
  background: rgba(238, 238, 238, 0.966);
  transform: skewX(-20deg);
  animation: loader 1s linear infinite;
}

@keyframes loader {
  0% {
    transform: translateX(-150%);
  }

  50% {
    transform: translateX(-60%);
  }

  100% {
    transform: translateX(150%);
  }
}

@keyframes graphloader {
  0% {
    transform: translateX(150%);
  }

  50% {
    transform: translateX(-60%);
  }

  100% {
    transform: translateX(-150%);
  }
}
